import {
  Autocomplete,
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useQuery, gql, useLazyQuery, useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { Box } from "@mui/system";
import { DataGridPro, GridToolbar, GridActionsCellItem, useGridApiRef, gridQuickFilterValuesSelector } from "@mui/x-data-grid-pro";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { format } from "date-fns";
import { AuthenticatedContext, PreferenceContext } from "../App";
import { useSnackbar } from "notistack";
import DeleteIcon from "@mui/icons-material/Delete";
import { GET_ALL_COURSES, GET_COURSE_CURRICULA, GET_RECOMMENDATION_STUDENTS, GET_REPORT_STUDENTS } from "../graphql/queries";
import { CREATE_NOTIFICATIONS, GENERATE_TRAINING_RECOMMENDATIONS } from "../graphql/mutations";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TestReport from "./TestReport";

const TrainingAllocation = ({ program_id }) => {
  const { preferences, setPreferences, defaults } = React.useContext(PreferenceContext);
  const { userRole } = React.useContext(AuthenticatedContext);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [curriculum, setCurriculum] = useState(null);
  const [curricula, setCurricula] = useState([]);
  const [rows, setRows] = useState([]);
  const [trainingDialog, setTrainingDialog] = useState(false);

  const [getCourseCurricula, { regLoading, regError, regData }] = useLazyQuery(GET_COURSE_CURRICULA, {
    onCompleted: (courseCurricula) => {
      setCurricula(courseCurricula.getCourseCurricula);
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const [getRecommendationStudents, { studentsLoading, studentsError, studentsData }] = useLazyQuery(GET_RECOMMENDATION_STUDENTS, {
    fetchPolicy: "no-cache",
    onCompleted: ({ getRecommendationStudents }) => {
      if (getRecommendationStudents.length > 0) {
        setRows(getRecommendationStudents);
      }
    }
  })

  const [generateTrainingRecommendations] = useMutation(GENERATE_TRAINING_RECOMMENDATIONS, {
    onCompleted: ({ generateTrainingRecommendations }) => {
      if (generateTrainingRecommendations) {
        enqueueSnackbar(
          `Training generation successful`,
          {
            variant: "success",
          }
        );
        getRecommendationStudents({
          variables: {
            program_id
          }
        });
      } else {
        enqueueSnackbar(`There was a problem with generating training recommendations, please try again.`,
          {
            variant: "warning",
          }
        );
      }
    }
  });

  const getNumRecommend = () => {
    return rows.filter(r => [...new Set(r.Results.map(re => re.psType))].length === 6 && r.Trainings.length === 0).length;
  }

  useEffect(() => {
    getRecommendationStudents({
      variables: {
        program_id
      }
    });
    getCourseCurricula({
      variables: {
        program_id
      }
    });
  }, []);

  function getStudentName(params) {
    return params.row.givenname + " " + params.row.surname;
  };

  function getCampus(params) {
    return params.row.Centre?.name;
  }

  function getIsGenerated(params) {
    return params.row.Trainings?.length > 0;
  }

  function getIsReportGenerated(params) {
    if (params.row.Results?.length === 0) return false;
    return [...new Set(params.row.Results.map(r => r.psType))].length === 6;
  }

  function getIsPaid(params) {
    return params.row.Trainings?.filter(t => t.isPaid || t.registrationId).length > 1;
  }

  const getApplyFilterFnName = (value) => {
    if (!value) {
      return null;
    }
    let re = new RegExp(value, "i");
    return (params) => {
      return re.test(params.value);
    };
  };

  const generateTrainings = () => {
    const generate_ids = rows.filter(r => [...new Set(r.Results.map(re => re.psType))].length === 6 && r.Trainings.length === 0).map(r => r.id);
    generateTrainingRecommendations({
      variables: {
        program_id,
        generate_ids,
        curriculum_id: curriculum.id
      }
    });
    setTrainingDialog(false);
  }

  const columns = useMemo(
    () => [
      {
        field: "login",
        headerName: "Student Number",
        flex: 1,
        headerAlign: "center",
        align: "center",
        getApplyQuickFilterFn: undefined,
      },
      {
        field: "studentName",
        headerName: "Student Name",
        flex: 3,
        headerAlign: "center",
        align: "center",
        filterable: false,
        getApplyQuickFilterFn: getApplyFilterFnName,
        valueGetter: getStudentName,
      },
      {
        field: "campus",
        headerName: "Campus",
        headerAlign: "center",
        align: "center",
        flex: 1,
        getApplyQuickFilterFn: undefined,
        valueGetter: getCampus,
      },
      {
        field: "reports",
        headerName: "Results Generated?",
        flex: 1,
        headerAlign: "center",
        align: "center",
        getApplyQuickFilterFn: undefined,
        valueGetter: getIsReportGenerated,
        type: "boolean"
      },
      {
        field: "training",
        headerName: "Training Generated?",
        flex: 1,
        headerAlign: "center",
        align: "center",
        getApplyQuickFilterFn: undefined,
        valueGetter: getIsGenerated,
        type: "boolean"
      },
      {
        field: "paid",
        headerName: "Training Paid?",
        flex: 1,
        headerAlign: "center",
        align: "center",
        getApplyQuickFilterFn: undefined,
        valueGetter: getIsPaid,
        type: "boolean"
      },
    ],
    [rows]
  );

  return (
    <Container maxWidth="false" sx={{ mt: 3, mb: 3 }}>
      <Dialog open={trainingDialog}>
          <DialogTitle>Generate Training Recommendations</DialogTitle>
          <DialogContent>
            Are you sure you want to generate recommended training modules to { getNumRecommend() } students?
          </DialogContent>
          <DialogActions sx={{justifyContent: "space-between"}}>
            <Button onClick={() => setTrainingDialog(false)}>Cancel</Button>
            <Button onClick={() => generateTrainings()}>Generate Recommendations</Button>
          </DialogActions>
      </Dialog>
      <Paper elevation={0} sx={{ p: 3 }}>
        <Box
          sx={{
            display: "flex",
            mb: 3,
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box />
          { userRole === "Admin" && (
            <>
              <Box sx={{ flex: 1 }}>
                <Autocomplete
                  options={curricula}
                  getOptionLabel={(option) => `${option.Course?.name} ${option.Grade?.name}`}
                  renderInput={(params) => <TextField {...params} label="Curriculum" margin="normal" required />}
                  value={curriculum}
                  onChange={(event, newValue) => { 
                    setCurriculum(newValue);
                  }}
                  sx={{ width: 400, mr: 2 }}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                />
              </Box>
              <Box sx={{ flex: 1 }}>
                <Button color="primary" gutterBottom variant="contained" sx={{ mb: 0, ml: 3 }} onClick={() => setTrainingDialog(true)} disabled={!curriculum}>
                  Generate Training Recommendations
                </Button>
              </Box>
            </>
          )}
        </Box>
        <DataGridPro
          rows={rows}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true
            }
          }}
          disableSelectionOnClick
          autoHeight
          loading={studentsLoading}
          pagination
        />
      </Paper>
    </Container>
  );
};

export default TrainingAllocation;
